import React, { Component, useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import validator from "@rjsf/validator-ajv8";
import { RegistryWidgetsType, WidgetProps } from "@rjsf/utils";
import Form from "@rjsf/core";
import { useLocation, BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import InputMask from "react-input-mask";
import * as babel from 'babel-standalone';
import { Helmet } from "react-helmet";

const log = (type) => console.log.bind(console, type);

let functionexports = {};

class FormImage extends Component {
  state = {
    src: '',
    alt: '',
    width: '',
    align: ''
  };
  constructor(props) {
    super(props);
    this.state = {
      src: props.schema.properties.src.const,
      alt: props.schema.properties.alt.const,
      width: (props.schema.properties.width.const ? props.schema.properties.width.const : '100%') ,
      align: (props.schema.properties.align.const ? props.schema.properties.align.const : 'left') 
    };
    // console.log(props.schema.properties);    
  }
  render() {
    return (
      <div style={{ textAlign: (this.state.align ? this.state.align : 'left') }}>
        <img src={this.state.src} alt={this.state.alt} width={this.state.width}/>
      </div>
    )
  }
}

function App() {
  const [theme, setTheme] = useState("");
  const [disabledForm, setDisabled] = useState("");
  const [token, setToken] = useState("");
  const [formSchema, setFormSchema] = useState();
  const [formSchemaTY, setFormSchemaTY] = useState();
  const [formUISchema, setFormUISchema] = useState();
  const [formData, setFormData] = useState();
  // const [currentFormData, setCurrentFormData] = useState();
  const [formConfig, setFormConfig] = useState();
  const [formCustomStyle, setFormCustomStyle] = useState(String);
  const [formFunctions, setFormFunctions] = useState(String);
  const [formSPALayout, setFormSPALayout] = useState(String);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const location = useLocation();
  const customFields = {
    image: FormImage
  }
  const customWidgets: RegistryWidgetsType = { MaskWidget: MaskWidget, ActionButtonWidget: ActionButtonWidget };
  const vm = require('vm');
  let currentFormData = {};

  function MaskWidget(props: WidgetProps) {
    // console.log('props', props);
    let blurhandler = (props.schema.properties.action && props.schema.properties.action.const ? props.schema.properties.action.const : '');
    let elements = (props.id).split('_');
    elements.shift();
    
    let alwaysshowmask = (props.schema.properties.mask && props.schema.properties.mask.alwayshowmask ? props.schema.properties.mask.alwayshowmask : true);
    let beforemaskedstatechange = (props.schema.properties.mask && props.schema.properties.mask.beforemaskedstatechange ? eval(props.schema.properties.mask.beforemaskedstatechange) : null);
    // Vamos montar um array para esse elemento
    return (
      <>
        <InputMask
          mask={props.schema.properties.mask.const}
          alwaysShowMask={alwaysshowmask}
          beforeMaskedStateChange={beforemaskedstatechange}
          className="form-control"
          value={props.value}
          required={props.required}
          onBlur={e => blurHandler(e, elements, token, blurhandler)}
          onChange={e => { 
            props.onChange(e.target.value);
            currentFormData = {
              ...formData,
              ...currentFormData              
            };
            var obj ={};
            var tmp = obj;
            for (var i = 0,n = elements.length; i<n; i++){ // comeca do 1 pq a primeira parte é o root
              if(i === elements.length - 1){
                tmp[elements[i]] = e.target.value;  
              }else{
                if(!tmp.hasOwnProperty(elements[i])){
                  tmp[elements[i]] = {};
                }
                tmp = tmp[elements[i]];
              }
            }
            currentFormData = {
              ...currentFormData,
              ...obj
            };
            // console.log('currentFormData', currentFormData);
          }}
        />
      </>
    );
  }

  function ActionButtonWidget(props: WidgetProps) {
    // console.log('props', props);
    let clickhandler = (props.schema.properties.action && props.schema.properties.action.const ? props.schema.properties.action.const : '');
    let btnColor = (props.schema.properties.buttonType && props.schema.properties.buttonType.const ? props.schema.properties.buttonType.const : 'info');
    let btnText = (props.schema.properties.buttonText && props.schema.properties.buttonText.const ? props.schema.properties.buttonText.const : 'Enviar');

    return (
      <>
      {disabledForm && 
        <button
          className={"btn btn-" + btnColor}
          onClick={e => clickHandler(e, token, clickhandler)}
          disabled
        >Aguarde...
        </button>
      }
      {!disabledForm && 
        <button
          className={"btn btn-" + btnColor}
          onClick={e => clickHandler(e, token, clickhandler)}
        >{btnText}
        </button>
      }
      </>
    );
  }

  const blurHandler = async (e, elements) => {
    var obj ={};
    currentFormData = {
      ...formData,
      ...currentFormData
    };
    var tmp = obj;
    for (var i = 0,n = elements.length; i<n; i++){
      if(i === elements.length - 1){
        tmp[elements[i]] = e.target.value;  
      }else{
        if(!tmp.hasOwnProperty(elements[i])){
          tmp[elements[i]] = {};
        }
        tmp = tmp[elements[i]];
      }
    }
    currentFormData = {
      ...currentFormData,
      ...obj
    };
    // console.log('currentFormData', currentFormData);
  }

  const clickHandler = async (e, token, action) => {
    setDisabled(true);
    e.preventDefault();
    let contextPayload = {
      "context": action,
      "params": currentFormData
    };
    if (!action) return;
    let acc = functionexports.formfunctions(contextPayload);
    await runAction(acc, token);
    setDisabled(false);
  }

/**
 * Format to Brazilian currency
 */
const maskToCurrency = ({ nextState }) => {
  const { value } = nextState || {}

  let amountFormatted = value?.replace?.(/\D/g, '')
  amountFormatted = amountFormatted?.replace?.(/^0+/g, '')

  if (amountFormatted?.length === 2) {
    return {
      ...nextState,
      value: `R$ ${amountFormatted}`,
      selection: {
        start: amountFormatted.length + 3,
        end: amountFormatted.length + 3
      }
    }
  }

  const amountFormattedWithComma = amountFormatted?.replace?.(
    /(?=\d{2})(\d{2})$/,
    ',$1'
  )
  const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
    /(\d)(?=(\d{3})+(?!\d))/g,
    '$1.'
  )

  if (amountFormattedWithDot) {
    return {
      ...nextState,
      value: `R$ ${amountFormattedWithDot}`,
      selection: {
        start: amountFormattedWithDot.length + 3,
        end: amountFormattedWithDot.length + 3
      }
    }
  }
  return nextState
}

  function isObjectEmpty(obj) {
    return obj // 👈 null and undefined check
      && Object.keys(obj).length === 0
      && Object.getPrototypeOf(obj) === Object.prototype
  }

  async function sendingWFEvent(payload, tenantId, startmsg, processId, authstring) {
    try {
      // let auth = process.env.CAMUNDA_AUTH;
      let apikey = process.env.REACT_APP_MEVIO_APIKEY;
      // Após receber uma mensagem, vamos enviar uma chamada para o endpoint dos Workflows do Mevio, iniciando um processo por mensagem
      // console.log('payload', JSON.stringify(payload));
      // Convertendo o payload no formato que queremos
      let payloadObject = {};
      Object.keys(payload).forEach(key => {
        //payloadObject[key] = { value: JSON.stringify(payload[key]) }
        payloadObject[key] = { value: (payload[key] ? (typeof payload[key] == 'object' ? JSON.stringify(payload[key]) : payload[key]) : "") }
      });
      let data = {
        messageName: startmsg,
        resultEnabled: true,
        processVariables: payloadObject,
        tenantId: tenantId
      };
      if (processId && processId !== "") {
        data = {
          messageName: startmsg,
          processInstanceId: processId,
          resultEnabled: true,
          processVariables: payloadObject,
          tenantId: tenantId
        };
      }
      // console.log('data', data);

      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_MEVIO_APIENDPOINT}workflows/message`,
        headers: {
          'Content-Type': 'application/json',
          'apikey': apikey,
          'Authorization': 'Basic ' + authstring
        },
        data: data
      };
      return axios(config)
        .then(function (response) {
          console.log('✅ Event sent to the Workflow. Thank you.');
          return true;
        })
        .catch(function (error) {
          console.log('❌ Error sending Workflow event.', error);
          return;
        });
    } catch (e) {
      console.log('Invalid input.');
      return;
    }
  }

  async function runAction(action, token) {
    let config;
    action.headers['Authorization'] = `Bearer ${token}`;
    switch (action.action) {
      case "callEndpoint":
        config = {
          method: action.method,
          url: action.url,
          headers: action.headers
        };
        if (!isObjectEmpty(action.data)) {
          config.data = action.data;
        }
        const response = await axios(config);
        // console.log('config', config);
        if (response && response.data) {
          // console.log(response.data);
          let newFormData:any = {
            ...currentFormData
          };
          let respformat = action.responseformat;
          let valuearr = [];
          var obj ={};          
          Object.entries(respformat).forEach((entry) => {
            var tmp = obj;            
            const [key, value] = entry;
            if(value && value !== ''){
              // console.log('value', value)
              valuearr = value.split('.');
              for (var i = 0,n = valuearr.length; i < n; i++){
                if(i === n-1){
                  tmp[valuearr[i]] = response.data[key];  
                }else{
                  if(!tmp.hasOwnProperty(valuearr[i])){
                    tmp[valuearr[i]] = {};
                  }
                  tmp = tmp[valuearr[i]];
                }
              }
            }
          });
          // Se eu nao tenho um value quer dizer que a resposta 
          // fornecida no endpoint nao tem equivalencia no form
          newFormData = {
            ...newFormData,
            ...obj
          };
          //console.log('newFormData', newFormData);
          setFormData(newFormData);
          return 'ok';
        } else {
          console.error('error');
          return;
        }
      case 'sendWFEvent':
        console.log('Chamando Workflow');
        return await sendingWFEvent(action.payload, action.tenantId, action.eventMessage, action.processID, action.authstring).then(send => { return send; });
      default:
        console.log('Bora la', action);
        return;
    }
  }

  const getFormSchema = useCallback(async (appkey,formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
    const config = {
      method: 'get',
      url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formschema.json`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
      }
    };
    const response = await axios(config);
    if (response) {
      console.log('✅ Remote Form Schema loaded.');
      if (response.data && response.data.content) {
        setFormSchema(JSON.parse(decodeURIComponent(escape(atob(response.data.content)))));
      }
    } else {
      console.error('error');
    }
  }, [setFormSchema]);

  const getFormSchemaTY = useCallback(async (appkey, formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
    const config = {
      method: 'get', 
      url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formschematy.json`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
      }
    };
    const response = await axios(config);
    if (response) {
      console.log('✅ Remote Form Schema TY loaded.');
      if (response.data && response.data.content) {
        setFormSchemaTY(JSON.parse(decodeURIComponent(escape(atob(response.data.content)))));
      }
    } else {
      console.error('error');
    }
  }, [setFormSchemaTY]);

  const getFormUISchema = useCallback(async (appkey,formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
      const config = {
        method: 'get',
        url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formuischema.json`,
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
        }
      };
    try {      
      const response = await axios(config);
      if (response) {
        console.log('✅ Remote Form UI Schema loaded.');
        if (response.data && response.data.content) {
          setFormUISchema(JSON.parse(decodeURIComponent(escape(atob(response.data.content)))));
        }
      }
    } catch (e) {
      console.log('Remote Form UI Schema not Found.',e)
    }
  }, [setFormUISchema]);

  const getFormData = useCallback(async (appkey, formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
    const config = {
      method: 'get',
      url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formdata.json`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
      }
    };
    try{
      const response = await axios(config);
      if (response) {
        console.log('✅ Remote Form Data Schema loaded.');
        if (response.data && response.data.content) {
          setFormData(JSON.parse(decodeURIComponent(escape(atob(response.data.content)))));
        }
      } else {
        console.error('No Remote Form Data found.');
      }
    } catch (e) {
      console.log('Remote Form Data not Found.', e)
    }
  }, [setFormData]);

  const getFormConfig = useCallback(async (appkey, formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
    const config = {
      method: 'get',
      url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formconfig.json`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
      }
    };
    try {
      const response = await axios(config);
      if (response) {
        console.log('✅ Remote Form Config loaded.');
        if (response.data && response.data.content) {
          setFormConfig(JSON.parse(decodeURIComponent(escape(atob(response.data.content)))));
        }
      } else {
        console.error('No Remote Form Config found.');
      }
    } catch (e) {
      console.log('Remote Form Config not Found.', e)
    }
  }, [setFormConfig]);  

  const getFormCustomStyle = useCallback(async (appkey, formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
    const config = {
      method: 'get',
      url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formstyle.css`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
      }
    };
    try {
      const response = await axios(config);
      if (response) {
        console.log('✅ Remote Form Style loaded.');
        if (response.data && response.data.content) {
          setFormCustomStyle(decodeURIComponent(escape(atob(response.data.content))));
        }
      } else {
        console.error('No Remote Form Custom Style found.');
      }
    } catch (e) {
      console.log('Remote Form Custom Style not Found.', e)
    }
  }, [setFormCustomStyle]);  

  const getFormFunctions = useCallback(async (appkey, formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
    const config = {
      method: 'get',
      url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formfunctions.js`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
      }
    };
    const response = await axios(config);
    if (response) {
      console.log('✅ Remote Form Functions loaded.');
      console.log('Functions: ',response.data);
      if (response.data && response.data.content) {
        setFormFunctions(decodeURIComponent(escape(atob(response.data.content))));
      }
    } else {
      console.error('error');
    }
  }, [setFormFunctions]);

  const getFormSPALayout = useCallback(async (appkey, formid) => {
    if (!appkey) { console.log('No appkey provided.'); return; }
    if (!formid) { console.log('No form Id provided.'); return; }
    const config = {
      method: 'get',
      url: `https://api.github.com/repos/OctavioSI/api-juridica-aux/contents/json-schema-form/${appkey}/${formid}.formspalayout.js`,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`
      }
    };
    const response = await axios(config);
    if (response) {
      console.log('✅ Remote Form SPA Layout loaded.');
      console.log('SPA Layout: ',response.data);
      if (response.data && response.data.content) {
        setFormSPALayout(decodeURIComponent(escape(atob(response.data.content))));
      }
    } else {
      console.error('error');
    }
  }, [setFormSPALayout]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    /** Theme */
    let themetmp = queryParams.get('theme');    
    if (!themetmp) themetmp = 'lumen';    
    const themeurl = `./themes/${themetmp}.css`;
    setTheme(themeurl);

    let tokentmp = queryParams.get('token');
    if (!tokentmp) console.log('A valid token is required.');
    const tk = `${tokentmp}`;
    setToken(tk);

    /** FormSchema */
    let formSchemaTmp = queryParams.get('formSchema');
    let formSchemaTYTmp = queryParams.get('formSchemaTY');
    let formUISchemaTmp = queryParams.get('formUISchema');
    let formDataTmp = queryParams.get('formData');
    let formConfigTmp = queryParams.get('formConfig');
    let formCustomStyleTmp = queryParams.get('formCustomStyle');
    let appkey = queryParams.get('appkey');
    let formid = queryParams.get('formid');
    let title = queryParams.get('title');

    document.title = (title ? title : 'Mevio :: Formulários');

    if (!formSchemaTmp){
      getFormSchema(appkey,formid).catch(console.error);
    }else{
      setFormSchema(JSON.parse(decodeURIComponent(escape(atob(formSchemaTmp)))));
    }

    if (!formSchemaTYTmp) {
      getFormSchemaTY(appkey, formid).catch(console.error);
    } else {
      setFormSchemaTY(JSON.parse(decodeURIComponent(escape(atob(formSchemaTYTmp)))));
    }
    
    if (!formUISchemaTmp) {
      getFormUISchema(appkey, formid).catch(console.error);
    }else{
      setFormUISchema(JSON.parse(decodeURIComponent(escape(atob(formUISchemaTmp)))));
    }

    if (!formDataTmp) {
      getFormData(appkey, formid).catch(console.error);
    } else {
      setFormData(JSON.parse(decodeURIComponent(escape(atob(formDataTmp)))));
    }
     
    if (!formConfigTmp) {
      getFormConfig(appkey, formid).catch(console.error);
    } else {
      setFormConfig(JSON.parse(decodeURIComponent(escape(atob(formConfigTmp)))));
    }

    if (!formCustomStyleTmp) {
      getFormCustomStyle(appkey, formid).catch(console.error);
    } else {
      setFormCustomStyle(decodeURIComponent(escape(atob(formCustomStyleTmp))));
    }

    // Nunca vou chamar functions passadas na URL
    getFormFunctions(appkey, formid).catch(console.error);

    // Nunca vou chamar SPA layout passado na URL
    getFormSPALayout(appkey, formid).catch(console.error);

  }, [getFormSchema, getFormSchemaTY, getFormUISchema, getFormData, getFormConfig, getFormCustomStyle, getFormFunctions, getFormSPALayout, location.search]);

  const onSubmit = async ({ formData }: any, e) => {
    //console.log('submit', formData);
    setDisabled(true);
    let sendPayload;
    let sendConfig;
    console.log('Data submitted: ', formData);
    // console.log('Form Config ', formConfig);
    if(!formConfig){ return 'No Form Config provided.'; }

    switch(formConfig.targetService){
      /**
       * Se o nosso targetService for um Workflow, vamos
       * montar um payload apropriado para esta request
       */
      case "workflow":
        console.log('Workflow started.');
        // Basic validation of fields
        if (!formConfig.messageName || formConfig.messageName === ""){ console.log('No messageName provided.'); return; }
        if (!formConfig.tenantId || formConfig.tenantId === "") { console.log('No tenantId provided.'); return; }
        // Primeiro vamos carregar as variaveis de state, ou seja, as variaveis que vieram no payload de config
        let formVariables = {};
        if (formConfig.variables && Object.keys(formConfig.variables).length > 0 && Object.getPrototypeOf(formConfig.variables) === Object.prototype){
          Object.keys(formConfig.variables).forEach(key => {
            formVariables[key] = { "value": formConfig.variables[key] }
          });
        }
        // Agora vamos inserir nas variaveis também as respostas do form
        //Object.keys(formData).forEach(key => {
        //  formVariables[key] = { "value": formData[key] }
        //});
        formVariables['formData'] = formData;
        let innerPayload;      
        innerPayload = {
          "messageName": formConfig.messageName,
          "processVariables": formVariables,
          "resultEnabled" : true,
          "tenantId": formConfig.tenantId
        };
        sendPayload = {
          "messageName": "newFormSubmission",
          "processVariables": {
            "payload": { "value": JSON.stringify(innerPayload) }
          },
          "resultEnabled": true,
          "tenantId": "mevio"
        };

        sendConfig = {
          method: 'post',
          url: `${process.env.REACT_APP_MEVIO_APIENDPOINT}workflows/message`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${process.env.REACT_APP_CAMUNDA_AUTHKEY}`,
            'apikey': `${process.env.REACT_APP_MEVIO_APIKEY}`
          },
          data: sendPayload
        };
        try {
          const response = await axios(sendConfig);
          if(response.data){
            console.log('Workflow iniciado com sucesso!');
            setFormSubmitted(true);
          }
        }catch(e){
          console.log('error', e);
        }
        break;

      /**
       * Como padrão, vamos retornar um erro já que o Form
       * deve fornecer um targetService válido 
       */  
      default:
        console.log('A valid service is required.');
        break;
    }
  };

  const onChange = async ({ formData }: any) => { 
    // console.log(formData);
    currentFormData = {
      ...currentFormData,
      ...formData
    };
  };

  const updateOtherForms = (newContent) => {
    this.props.updatePartOfStateObject('formData', newContent)
  }


  if (formSchema){

      // Vamos carregar custom style se houver
      if(formCustomStyle){
        var head = document.head;
        var style = document.createElement("style");
        style.type = "text/css";
        style.appendChild(document.createTextNode(formCustomStyle))
        head.appendChild(style);
      }

      if(formFunctions){
        // console.log('Has functions', formFunctions) ;
        functionexports = vm.runInThisContext(formFunctions);
      }

    if(!formSPALayout){

      return (
        <>
          <link rel="stylesheet" type="text/css" href={theme} />
          { formSubmitted 
            ?
              <div className='thankyoupage'>
              {formSchemaTY && formSchemaTY.title ? formSchemaTY.title : 'Obrigado' }
              <br />
              {formSchemaTY && formSchemaTY.subtitle ? formSchemaTY.subtitle : 'As respostas foram enviadas com sucesso!'}
              </div>
            : 
              <div className='card'>
                <Form 
                  schema={formSchema}
                  uiSchema={formUISchema}
                  formData={formData}
                  validator={validator}
                  onSubmit={onSubmit}
                  onChange={onChange}
                  onError={log("errors")} 
                  fields={customFields}
                  widgets={customWidgets}
                  disabled={disabledForm}
                  formContext={{ updateOtherForms: updateOtherForms }}
                >
                {!disabledForm && <button type="submit" className="btn btn-info">Enviar informações</button>}
                {disabledForm && <button className="btn btn-info" disabled>Aguarde...</button>}
              </Form>
            </div>
          }
        </>
      );
      
    }else{ // has formSPALayout
      // console.log('formSPALayout',formSPALayout)
      const babelCode = babel.transform(formSPALayout, { presets: ['react', 'es2015'] }).code;
      // console.log('babelCode',babelCode)
      const formSPALayoutCode = babelCode.replace("'use strict';", "").replace('"use strict";', "").trim();
      const funclayout = new Function(
        "React", 
        "Helmet",
        "Form", 
        "formSchema",
        "formUISchema",
        "formData",
        "validator",
        "customFields",
        "customWidgets",
        "disabledForm",
        "onSubmit",
        "onChange",
        "log",
        "updateOtherForms",
        "axios",
        `return ${formSPALayoutCode}`
      );    
  
      return (
        <>
          <link rel="stylesheet" type="text/css" href={theme} />
          {
          funclayout(
                React, 
                Helmet,
                Form, 
                formSchema,
                formUISchema,
                formData,
                validator,
                customFields,
                customWidgets,
                disabledForm,
                onSubmit,
                onChange,
                log,
                updateOtherForms,
                axios
              ) 
          }
        </>
      )
    }
  }

  return (<><div></div></>);

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Router>
      <App />
    </Router>
  </>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
